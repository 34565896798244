import React, { useEffect, useState } from "react";
import "./DaySchedule.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCheck, faMinus } from "@fortawesome/free-solid-svg-icons";

const DaySchedule = ({ api, clockData }) => {
  const { index } = clockData;

  const [selectedDay, setSelectedDay] = useState(null);
  const [showInputs, setShowInputs] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [schedules, setSchedules] = useState({});

  const days = [
    { short: "ש", full: "שבת" },
    { short: "ו", full: "שישי" },
    { short: "ה", full: "חמישי" },
    { short: "ד", full: "רביעי" },
    { short: "ג", full: "שלישי" },
    { short: "ב", full: "שני" },
    { short: "א", full: "ראשון" },
  ];

  useEffect(() => {
    // Function to transform the schedule data
    const transformSchedule = (daySchedule) => {
      return daySchedule.map((timeSpan) => {
        if (
          timeSpan.startHour === 0 &&
          timeSpan.startMinute === 0 &&
          timeSpan.endHour === 0 &&
          timeSpan.endMinute === 0
        ) {
          return "";
        } else {
          return {
            startTime: `${String(timeSpan.startHour).padStart(2, '0')}:${String(timeSpan.startMinute).padStart(2, '0')}`,
            endTime: `${String(timeSpan.endHour).padStart(2, '0')}:${String(timeSpan.endMinute).padStart(2, '0')}`,
          };
        }
      }).filter(time => time !== "");
    };

    // Initialize schedules from clockData
    const serverSchedules = {
      ראשון: transformSchedule(clockData.schedule[0].Schedule || []),
      שני: transformSchedule(clockData.schedule[1].Schedule || []),
      שלישי: transformSchedule(clockData.schedule[2].Schedule || []),
      רביעי: transformSchedule(clockData.schedule[3].Schedule || []),
      חמישי: transformSchedule(clockData.schedule[4].Schedule || []),
      שישי: transformSchedule(clockData.schedule[5].Schedule || []),
      שבת: transformSchedule(clockData.schedule[6].Schedule || []),
    };

    setSchedules(serverSchedules);
  }, [clockData]); // Depend on clockData to update schedules when clockData changes

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setShowInputs(false);
  };

  const closePopup = () => {
    setSelectedDay(null);
    setShowInputs(false);
    setStartTime("");
    setEndTime("");
  };

  const handlePlusClick = () => {
    setShowInputs(true);
  };

  const handleOkClick = async () => {
    if (startTime && endTime) {
      const updatedSchedules = {
        ...schedules,
        [selectedDay]: [
          ...(schedules[selectedDay] || []),
          { startTime, endTime },
        ],
      };
      setSchedules(updatedSchedules);
      setShowInputs(false);
      setStartTime("");
      setEndTime("");

      // Save the updated schedule
      const plain = {
        day0: updatedSchedules["ראשון"] || [],
        day1: updatedSchedules["שני"] || [],
        day2: updatedSchedules["שלישי"] || [],
        day3: updatedSchedules["רביעי"] || [],
        day4: updatedSchedules["חמישי"] || [],
        day5: updatedSchedules["שישי"] || [],
        day6: updatedSchedules["שבת"] || [],
      };

      try {
        const response = await fetch(`${api}setSchedule?ClockIndex=${index}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "1234",
          },
          body: JSON.stringify({ plain }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    }
  };

  const handleDeleteClick = async (day, index) => {
    const updatedSchedules = {
      ...schedules,
      [day]: schedules[day].filter((_, i) => i !== index),
    };

    // Save the updated schedule
    const plain = {
      day0: updatedSchedules["ראשון"] || [],
      day1: updatedSchedules["שני"] || [],
      day2: updatedSchedules["שלישי"] || [],
      day3: updatedSchedules["רביעי"] || [],
      day4: updatedSchedules["חמישי"] || [],
      day5: updatedSchedules["שישי"] || [],
      day6: updatedSchedules["שבת"] || [],
    };

    try {
      const response = await fetch(`${api}setSchedule?ClockIndex=${index}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1234",
        },
        body: JSON.stringify({ plain }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }

    // Update state after successful save
    setSchedules(updatedSchedules);
  };

  return (
    <div className="weekdays-container">
      {days.map((day) => (
        <div
          key={day.short}
          className={`day ${
            schedules[day.full] && schedules[day.full].length > 0
              ? "highlight"
              : ""
          }`}
          onClick={() => handleDayClick(day.full)}
        >
          <span>{day.short}</span>
        </div>
      ))}
      {selectedDay && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={closePopup}>
              &times;
            </span>
            <h2>{selectedDay}</h2>
            <FontAwesomeIcon
              icon={faPlus}
              className="plus-icon"
              onClick={handlePlusClick}
            />
            <div className="divider"></div>
            <div className="schedule-list">
              {schedules[selectedDay] &&
                schedules[selectedDay].map((time, index) => (
                  <div key={index} className="schedule-item">
                    {`${time.startTime} - ${time.endTime}`}
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="minus-icon"
                      onClick={() => handleDeleteClick(selectedDay, index)}
                    />
                  </div>
                ))}
            </div>
            {showInputs && (
              <div className="time-inputs">
                <input
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
                <input
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
                <button
                  className="ok-button"
                  onClick={handleOkClick}
                  disabled={!startTime || !endTime}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DaySchedule;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faCheck } from "@fortawesome/free-solid-svg-icons";
import "./Head.css";
import {
  fetchUpdateClockName,
  fetchTurnOnOrTurnOff,
} from "../../EspApi/EspApi";

function Head({ clockData, setIsTimerStart, mode, setMode }) {
  const { name, leg, index } = clockData;
  const [newName, setNewName] = useState(name); // Initial name
  const [isEditing, setIsEditing] = useState(false); // Track if the input field is being edited

  useEffect(() => {
    // Update local state with incoming prop changes
    setNewName(name);
  }, [name]);



  const handleConfirm = () => {
    fetchUpdateClockName(newName, index)
      .then(() => {
        setIsEditing(false);
        // You might want to update the local state if required
      })
      .catch((error) => console.error("Error updating clock name:", error));
  };

  const handleChange = (event) => {
    setNewName(event.target.value); // Update name with input value
    setIsEditing(true); // Set editing to true
  };

  const handleToggleMode = () => {
    const newMode = mode === "Off" ? "On" : "Off";
    fetchTurnOnOrTurnOff(newMode, index)
      .then((data) => {
        setMode(newMode); // Update mode immediately
        if (newMode === "Off") {
          setIsTimerStart(false); // Stop the timer when mode is "Off"
        }
      })
      .catch((error) => console.error("Error toggling mode:", error));
  };

  return (
    <div>
      <div className="button-container">
        <input
          type="text"
          maxLength="15"
          value={newName}
          onChange={handleChange}
          onFocus={() => setIsEditing(true)} // Set editing to true when the input is focused
          className="input-bold"
        />
        {isEditing && (
          <button className="check-button" onClick={handleConfirm}>
            <FontAwesomeIcon icon={faCheck} />
          </button>
        )}
        <button
          onClick={handleToggleMode}
          className={mode === "On" ? "On" : "Off"}
        >
          <FontAwesomeIcon icon={faPowerOff} />
        </button>
        <div className="circle">
          <h1>{leg}</h1>
        </div>
      </div>
      <hr className="horizon-line" />
    </div>
  );
}

export default Head;

import logo from './logo.svg';
import './App.css';
import GreenWatch from './Componnent/GreenWatch/GreenWatch'
import RegisterAndLogin from './Componnent/RegisterAndLogin/RegisterAndLogin'
import { useState } from 'react';
function App() {
  const[validUsername,setValidUsername]=useState("false")
  return (
    <div className="App">
      {/* { <header className="App-header"> } */}
      <RegisterAndLogin setValidUsername={setValidUsername}/>
      {validUsername==="true"?  <GreenWatch/> :null}
      {/* </header> */}
    </div>
  );
}

export default App;


const api = "https://monkey-glowing-mako.ngrok-free.app/";

export const fetchGetAvailableClocksData = async () => {
  try {
    const response = await fetch(`${api}getAvailableClocksData`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "1234",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export const fetchGetIsValidUser = async (UserName,Password) => {
  try {
    const response = await fetch(
      `${api}getIsValidUser?UserName=${UserName}&Password=${Password}`,
      {
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "1234",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export const fetchGetIsServerConnecting = async () => {
  try {
    const response = await fetch(
      `${api}`,
      {
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "1234",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export const fetchGetCurrentTime = async () => {
  try {
    const response = await fetch(`${api}getCurrentTime`, {
      method: "GET",
      headers: {
        "ngrok-skip-browser-warning": "1234",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export const fetchGetTimeTimer = async (clockIndex) => {
  try {
    const response = await fetch(
      `${api}getTimeTimer?ClockIndex=${clockIndex}`,
      {
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "1234",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export const fetchGetMode = async (clockIndex) => {
  try {
    const response = await fetch(`${api}getMode?NumClock=${clockIndex}`, {
      method: "GET",
      headers: {
        "ngrok-skip-browser-warning": "1234",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export const fetchSetActivateClock = async (clockIndex) => {
  try {
    const response = await fetch(
      `${api}setActivateClock?ActivateClock=${clockIndex}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1234",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export const fetchSetUnActivateClock = async (clockIndex) => {
  try {
    const response = await fetch(
      `${api}setUnActivateClock?UnActivateClock=${clockIndex}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1234",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error("Error setting clock as inactive:", error);
    throw error;
  }
};

export const fetchUpdateClockName = async (newName, clockIndex) => {
  try {
    const response = await fetch(
      `${api}setUpdateClockName?ClockName=${newName}&ClockIndex=${clockIndex}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1234",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export const fetchTurnOnOrTurnOff = async (newMode, clockIndex) => {
  try {
    const response = await fetch(
      `${api}Turn${newMode}?NumClock=${clockIndex}`,
      {
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "1234",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export const fetchSetTimeTimer = async (clockIndex, timeTimer,mode) => {
  try {
    const response = await fetch(
      `${api}setTimeTimer?TimerTime=${timeTimer}&ClockIndex=${clockIndex}&mode=${mode}`,
      {
        method: "POST",
        headers: {
          "ngrok-skip-browser-warning": "1234",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglass } from "@fortawesome/free-solid-svg-icons";
import "./TimerSlider.css";
import { fetchSetTimeTimer } from "../../EspApi/EspApi";

const TimerSlider = ({
  clockData,
  setMode,
  setIsTimerStart,
  isTimerStart,
}) => {
  const { index, timeTimer } = clockData;
  const [timerValue, setTimerValue] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);
  const [firstRender, setFirstRender] = useState(true);
 

  useEffect(() => {
    if (!firstRender && timerValue === 0) {
      setTimeTimer(0);
      clearInterval(timerInterval);
      setTimerInterval(null);
      setIsTimerStart(false);
    }
  }, [timerValue]);

  useEffect(() => {
    if (isTimerStart) {
      startTimer();
    } else {
      clearInterval(timerInterval);
      setTimerInterval(null);
    }
  }, [isTimerStart]);

  useEffect(() => {
    setFirstRender(false);

  }, []);

  const startTimer = () => {
    clearInterval(timerInterval);
    const intervalId = setInterval(() => {
      setTimerValue((prevValue) => {
        if (prevValue > 0) {
          return prevValue - 1;
        } else {
          clearInterval(intervalId);
          setTimerInterval(null);
          setIsTimerStart(false);
          return 0;
        }
      });
    }, 1000);
    setTimerInterval(intervalId);
  };

  const setTimeTimer = async (mode) => {
    try {
      await fetchSetTimeTimer(index, timerValue, mode);
      if (mode === 0) {
        setMode("Off");
      }
    } catch (error) {
      console.error("Error setting time timer:", error);
    }
  };

  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value);
    setTimerValue(newValue);
    setIsTimerStart(false);
    //setMode("Off")
    // if (mode === "Off") {
    //   setTimeTimer(0);   

    // }
  };

  const updateSliderBackground = (value) => {
    const percentage = (value / 60) * 100;
    return `linear-gradient(to right, #007bff ${percentage}%, #ccc ${percentage}%)`;
  };

  return (
    <div className="timer-slider">
      <div className="slider-container">
        <FontAwesomeIcon
          icon={faHourglass}
          className="hourglass-icon"
          onClick={() => {
            setIsTimerStart((prevState) => !prevState);
            if (!isTimerStart) {
              setTimeTimer(1);
              setMode("timerOn");
            } else {
              setTimeTimer(0);
            }
          }}
          style={{ color: isTimerStart ? "green" : "red" }}
        />
        <input
          type="range"
          min="0"
          max="60"
          value={timerValue}
          onChange={handleSliderChange}
          style={{ background: updateSliderBackground(timerValue) }}
          className="slider"
        />
        <span className="timer-value">
          {timerValue} <span> S</span>
        </span>
      </div>
    </div>
  );
};

export default TimerSlider;

import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faGlobe } from "@fortawesome/free-solid-svg-icons";
import "./RegisterAndLogin.css";
import {
  fetchGetIsServerConnecting,
  fetchGetIsValidUser,
} from "../../EspApi/EspApi";

const RegisterAndLogin = ({ setValidUsername }) => {
  const [isRegister, setIsRegister] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showError, setShowError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [serverStatus, setServerStatus] = useState("disconnected");

  const nodeRef = useRef(null);

  useEffect(() => {
    handleIsServerConnecting();
    const savedUsername = localStorage.getItem("username");
    const savedPassword = localStorage.getItem("password");
    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleIsServerConnecting = async () => {
    try {
      const data = await fetchGetIsServerConnecting();
      setServerStatus(data);
    } catch (error) {
      console.error("Error");
    }
  };

  const handleModeSwitch = () => {
    setIsRegister(!isRegister);
    setIsRegister(false);
  };

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      const response = await fetchGetIsValidUser(username, password);
      if (response === "true") {
        setValidUsername("true");
        setIsLoggedIn(true);
        if (rememberMe) {
          localStorage.setItem("username", username);
          localStorage.setItem("password", password);
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("password");
        }
      } else {
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegister = () => {
    // Implement your register logic here
  };

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={!isLoggedIn}
      timeout={500}
      classNames="fade"
      unmountOnExit
    >
      <div className="register-and-login" ref={nodeRef}>
        <div className="register-and-login-content">
          <CSSTransition
            nodeRef={nodeRef}
            in={isRegister}
            timeout={300}
            classNames="slide"
            unmountOnExit
          >
            <div className="register-form">
              <h2>Register</h2>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <input type="email" placeholder="Email" />
              <button onClick={handleRegister}>Register</button>
            </div>
          </CSSTransition>
          <CSSTransition
            nodeRef={nodeRef}
            in={!isRegister}
            timeout={300}
            classNames="slide"
            unmountOnExit
          >
            <div className="login-form">
              <h2>Login</h2>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <div className="password-container">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  onClick={() => setShowPassword(!showPassword)}
                  className="password-icon"
                />
              </div>
              <div className="remember-me">
                <input
                  type="checkbox"
                  id="rememberMe"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <label htmlFor="rememberMe">Remember</label>
              </div>
              <button onClick={handleLogin}>
                {isLoading ? <Spinner /> : "Login"}
              </button>
              <FontAwesomeIcon
                icon={faGlobe}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: serverStatus === "disconnected" ? "red" : "green",
                }}
              />
            </div>
          </CSSTransition>
        </div>
        {/* <button className="switch-button" onClick={handleModeSwitch}>
          {isRegister ? 'Already have an account? Login' : "Don't have an account? Register"}
        </button> */}
        <CSSTransition
          in={showError}
          timeout={300}
          classNames="alert"
          unmountOnExit
        >
          <div className="alert">Wrong username or password</div>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
};

const Spinner = () => (
  <div className="spinner">
    <div className="loader"></div>
  </div>
);

export default RegisterAndLogin;

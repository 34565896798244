import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./Square.css";
import Head from "../Head/Head";
import TimerSlider from "../TimerSlider/TimerSlider";
import DaySchedule from "../DaySchedule/DaySchedule";
import { fetchSetUnActivateClock } from "../../EspApi/EspApi";

function Square({ api, clockData, setClocksData, allClocksData, setShowNotification }) {
  const { index } = clockData;
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState(clockData?.mode);
  const [isTimerStart, setIsTimerStart] = useState(false);

  useEffect(() => {
    // This will run every time `clockData` changes
    setMode(clockData?.mode);
  }, [clockData]); // Dependency array: React will run this effect when `clockData` changes

  const handleDelete = async () => {
    try {
      await fetchSetUnActivateClock(index);
      const updatedData = allClocksData.map((item) => {
        if (item.index === index) {
          const clearedSchedule = item.schedule?.map((day) => ({
            ...day,
            timeSpans: day.timeSpans?.map((span) => ({
              ...span,
              startHour: 0,
              startMinute: 0,
              endHour: 0,
              endMinute: 0,
            })),
          }));

          return {
            ...item,
            isActive: 0,
            mode: "Off",
            name: "שעון חדש! בחר שם",
            schedule: clearedSchedule,
            timeTimer: "0",
          };
        }
        return item;
      });
      setClocksData(updatedData);
      setShowModal(false);
    } catch (error) {
      console.error("Error deleting clock:", error);
    }
  };

  return (
    <div className={`square ${mode !== "Off" ? "active" : ""}`}>
      <Head
        clockData={clockData}
        setShowNotification={setShowNotification}
        mode={mode}
        setMode={setMode}
        setIsTimerStart={setIsTimerStart}
        isTimerStart={isTimerStart}
      />
      <h1></h1>
      <TimerSlider
        clockData={clockData}
        setShowNotification={setShowNotification}
        mode={mode}
        setMode={setMode}
        setIsTimerStart={setIsTimerStart}
        isTimerStart={isTimerStart}
      />
      <DaySchedule api={api} clockData={clockData} />

      <button className="trash-button" onClick={() => setShowModal(true)}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </button>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>?האם למחוק שעון זה וכל המידע הקשור לו</p>
            <div className="modal-buttons">
              <button className="confirm-button" onClick={handleDelete}>
                <FontAwesomeIcon icon={faCheck} />
              </button>
              <button className="cancel-button" onClick={() => setShowModal(false)}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Square;

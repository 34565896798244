import React, { useState, useEffect } from 'react';
import { fetchGetCurrentTime } from '../../EspApi/EspApi';

const ClockDisplay = () => {
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const fetchTime = async () => {
      try {
        const data = await fetchGetCurrentTime();
        setCurrentTime(data);
      } catch (error) {
        console.error('Error fetching current time:', error);
      }
    };

    // Fetch current time on mount
    fetchTime();

    // Set up interval to fetch current time every 10 seconds
    const interval = setInterval(fetchTime, 60000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  
  }, []);

  return (
    <div className="clock-container">
      <h1 className="clock-text">{currentTime}</h1>
    </div>
  );
};

export default ClockDisplay;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons"; // Import the new icon
import "./AddingClock.css";
import { fetchSetActivateClock } from "../../EspApi/EspApi";

function AddingClock({ clocksData, setClocksData }) {
  const [count, setCount] = useState(0); // Initialize count to 0

  useEffect(() => {
    const activeClocks = clocksData.filter((clock) => clock.isActive);
    setCount(activeClocks.length); // Update count after fetching data or on component mount
  }, [clocksData]); // Watch for changes in clockData to update count

  const addClock = async () => {
    let oneTime = false;
    const updateClocksData = clocksData.map((clock) => {
      if (clock.isActive === 0 && !oneTime) {
        oneTime = true;
        fetchSetActivateClock(clock.index)
          .then(() => {
            setClocksData((prevClocksData) =>
              prevClocksData.map((prevClock) =>
                prevClock.index === clock.index
                  ? { ...prevClock, name: "שעון חדש! בחר שם", isActive: 1 }
                  : prevClock
              )
            );
            setCount((prevCount) => prevCount + 1);
          })
          .catch((error) => console.error("Error activating clock:", error));
      }
      // Return the item unchanged if the condition is not met
      return clock;
    });
    setClocksData(updateClocksData);
  };

  return (
    <div className="container">
      <h1>הוספת שעון </h1>
      <button onClick={addClock}>
        <FontAwesomeIcon icon={faCirclePlus} className="fa-plus" />
      </button>
      <div className="counter">{`${count}/10`}</div>
    </div>
  );
}

export default AddingClock;

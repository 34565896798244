import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingDroplet } from "@fortawesome/free-solid-svg-icons";

import "./GreenWatch.css";
import AddingClock from "../AddingClock/AddingClock";
import Square from "../Square/Square";
import { fetchGetAvailableClocksData } from "../../EspApi/EspApi";
import ClockDisplay from "../ClockDisplay/ClockDisplay";

function GreenWatch() {
  const [clocksData, setClocksData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showNotification, setShowNotification] = useState(false);

  const api = "https://monkey-glowing-mako.ngrok-free.app/";

  useEffect(() => {
    const fetchClocksData = async () => {
      try {
        const data = await fetchGetAvailableClocksData();
        setClocksData(data);
      } catch (error) {
        console.error("Error fetching clocks data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchClocksData();
    const interval = setInterval(fetchClocksData, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="greenwatch-container">
      {showNotification && <div className="notification">השעון כבר דולק</div>}
      <div className="centered-row">
        <h1>
          <FontAwesomeIcon className="icon" icon={faHandHoldingDroplet} />
          <span className="green-text">Green</span>Watch
        </h1>
        <ClockDisplay />
      </div>
      <div className="AddingClock-container">
        <AddingClock clocksData={clocksData} setClocksData={setClocksData} />
      </div>
      <div className="clocks-container">
        {isLoading ? (
          <div className="loading-animation">
            <div className="drop"></div>
            <div className="drop"></div>
            <div className="drop"></div>
          </div>
        ) : (
          clocksData
            .filter((clock) => clock.isActive)
            .map((clock) => (
              <Square
                key={clock.index}
                api={api}
                clockData={clock}
                allClocksData={clocksData}
                setClocksData={setClocksData}
                setShowNotification={setShowNotification}
              />
            ))
        )}
      </div>
    </div>
  );
}

export default GreenWatch;
